body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .03rem;
}

.heading {
  font-size: 26px !important;
}

.aShadow {
  box-shadow: 0 0px 50px 0 rgba(0, 0, 0, .07);
}

.dataCard {
  border: 1px solid #f5f5ff !important;
  text-align: left;
}

.ant-descriptions-title {
  padding: 15px;
  font-size: 18px;
  border-bottom: 1px solid #eee;
  text-transform: uppercase;
  font-weight: 500;
}

.ant-descriptions-view {
  padding: 15px
}

.ant-spin-dot-item {
  background-color: #ffffff;
}

.roundedBtn {
  border-radius: 30px !important;
}